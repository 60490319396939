.notificationDot {
  position: absolute;
  top: 5px;
  left: 20px;

  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--c-danger);
}
