.navigationTabs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg-color);

  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.24);

  z-index: 101;
}

.navigationItem {
  font-size: 0.8rem;
  line-height: 1;
  min-width: 60px;
}

/* tablets only */
@media screen and (min-width: 576px) {
  .navigationTabs {
    padding-bottom: 20px;
  }
}
