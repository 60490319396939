.stubContainer {
  min-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  padding: 5px 0;
}

.stubText {
  text-align: left;
}

@media (max-width: 768px) {
  .stubText {
    text-align: center;
  }
}

img.stubBackgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}
